import { EventFormProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading, richtext } from "@schemas/presets";

const schema: Schema.Component<EventFormProps> = {
	schemaType: "component",
	component: "EventForm",
	displayName: "Form",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
					mandatory: false,
				},
				{
					...richtext,
					title: "Content",
					key: "content",
				},
				{
					title: "Campaign ID",
					key: "campaignID",
					type: "TextField",
					mandatory: true,
				},
				{
					title: "Button label",
					key: "buttonLabel",
					type: "TextField",
					placeholder: "Type a title",
				},
				{
					title: "Dynamic Fields of Basic Data",
					type: "CheckGroup",
					key: "dynamicFieldsOfBasicData",
					options: [
						{
							title: "Show Who Are You?",
							name: "showWhoAreYouField",
						},
						{
							title: "Show country field?",
							name: "showCountryField",
						},
						{
							title: "Show identity card?",
							name: "showIdentityCardField",
						},
						{
							title: "Show gender?",
							name: "showGenderField",
						},
						{
							title: "Show date of birth?",
							name: "showDateOfBirthField",
						},
					],
				},
				{
					title: "Dynamic Fields of Ticket Request",
					type: "CheckGroup",
					key: "dynamicFieldsTicketRequest",
					options: [
						{
							title: "Show input request for parent entries?",
							name: "showInputRequestForParentEntries",
						},
						{
							title: "Show input application for Degree Candidate Entries?",
							name: "showInputApplicationForDegreeCandidateEntries",
						},
					],
				},
				{
					title: "Dynamic Fields of Programs",
					type: "ConditionalField",
					key: "conditionalFieldsOfPrograms",
					hideable: true,
					options: [
						{
							value: "programs",
							title: "Programs Distributor",
							name: "programs",
						},
						{
							value: "program_type",
							title: "Programs type",
							name: "program_type",
						},
					],
					fields: [
						{
							type: "ReferenceField",
							title: "Programs",
							sources: [{ structuredData: "PROGRAM" }],
							selectionType: ["auto", "manual"],
							key: "data",
							condition: "programs",
						},
						{
							title: "Note",
							key: "noteGeneral",
							type: "NoteField",
							value: {
								title: "",
								text: "A selector will be displayed with the different types of programs",
							},
							condition: "program_type",
						},
					],
				},
			],
		},
	],

	default: {
		component: "EventForm",
		title: {
			content: "Lorem ipsum",
			tag: "h3",
		},
		campaignID: undefined,
		content: "Lorem ipsum paragraph",
		buttonLabel: "Enviar",
		conditionalFieldsOfPrograms: "programs",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "alpha",
			quantity: 0,
			sources: [{ structuredData: "PROGRAM" }],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/IntroForm/thumbnail@1x.png",
		"2x": "/thumbnails/modules/IntroForm/thumbnail@2x.png",
	},
};

export default schema;
