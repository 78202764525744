import { LandingProgramProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { DegreeType } from "@schemas/presets/degreeType";

import AccordionCollection from "../modules/AccordionCollection";
import BasicContent from "../modules/BasicContent";
import BasicText from "../modules/BasicText";
import FeaturedContent from "../modules/FeaturedContent";
import HeroProgram from "../modules/HeroProgram";

const schema: Schema.Template<LandingProgramProps> = {
	schemaType: "template",
	displayName: "Landing Program",
	component: "LandingProgram",
	dataPacks: ["LANDINGS_PACK"],
	type: { label: "Static", value: "static", mode: "detail" },

	config: [
		{
			type: "Select",
			key: "pageTranslationLanguage",
			title: "Custom Page translation language",
			helptext:
				"Override default page static translations with the selected language",
			options: [
				{ label: "English", value: "en" },
				{ label: "Spanish", value: "es" },
			],
		},
	],

	content: [
		{
			type: "ComponentArray",
			title: "Hero Content",
			key: "heroSection",
			contentType: "modules",
			whiteList: ["HeroProgram", "HeroLanding"],
			maxItems: 1,
		},
		{ ...DegreeType },
		{
			type: "ComponentArray",
			title: "Navigation Content",
			key: "navigationContent",
			contentType: "modules",
			whiteList: ["LandingProgramNavigation"],
			maxItems: 1,
		},

		{
			type: "ComponentArray",
			title: "Main Section",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"CypherCollection",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"FullImageWithText",
				"FullVideo",
				"HighlightQuote",
				"LandingIntro",
				"LocationMap",
				"LogoCollection",
				"NewsHighlightDistributor",
				"NewsSimpleDistributor",
				"OrderedCollection",
				"ProgramsDistributor",
				"QuoteTestimonial",
				"SocialShare",
				"TabsModules",
				"Wysiwyg",
			],
			key: "mainSection",
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "LandingProgram",
		abstract: "Lorem ipsum paragraph",
		pageTranslationLanguage: undefined,

		// HERO
		heroSection: {
			component: "Section",
			name: "Section",
			modules: [{ ...HeroProgram.default }],
		},

		// NAVIGATION
		navigationContent: {
			component: "Section",
			name: "Section",
			modules: [
				{
					component: "LandingProgramNavigation",
					landingForm: {
						component: "BasicIntroForm",
						form: {
							component: "BasicForm",
							title: {
								content: "Lorem ipsum",
								tag: "h3",
							},
							content: "Lorem ipsum paragraph",
							buttonLabel: "Enviar",
						},
						data: {
							mode: "auto",
							order: "alpha",
							quantity: 0,
							sources: [
								{
									structuredData: "PROGRAM",
								},
							],
						},
					},
					programTabs: [
						{
							component: "LandingProgramNavigationTab",
							title: "Presentación",
							elements: [{ ...FeaturedContent.default }],
						},
						{
							component: "LandingProgramNavigationTab",
							title: "Plan de Estudios",
							elements: [
								{ ...BasicText.default },
								{ ...AccordionCollection.default },
							],
						},
						{
							component: "LandingProgramNavigationTab",
							title: "Inserción Laboral",
							elements: [{ ...BasicContent.default }],
						},
					],
				},
			],
		},

		// MAIN SECTION
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProgramDetail/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProgramDetail/thumbnail@2x.png",
	},
};

export default schema;
