const downloadICS = (event: {
	start: [number, number, number, number, number];
	duration: { hours: number; minutes: number };
	title: string;
	location?: string;
	url: string;
}) => {
	const now = new Date();
	const dtstamp = `${now.getFullYear()}${(now.getMonth() + 1)
		.toString()
		.padStart(2, "0")}${now.getDate().toString().padStart(2, "0")}T${now
		.getHours()
		.toString()
		.padStart(2, "0")}${now.getMinutes().toString().padStart(2, "0")}${now
		.getSeconds()
		.toString()
		.padStart(2, "0")}`;

	const year = event.start[0].toString().padStart(4, "0");
	const month = event.start[1].toString().padStart(2, "0");
	const day = event.start[2].toString().padStart(2, "0");
	const hours = event.start[3].toString().padStart(2, "0");
	const minutes = event.start[4].toString().padStart(2, "0");

	function generateUID() {
		return Date.now().toString();
	}

	const icsData = `BEGIN:VCALENDAR\r\nVERSION:2.0\r\nPRODID:-//Cunef//Calendar//EN\r\nBEGIN:VEVENT\r\nUID:${generateUID()}\r\nDTSTAMP:${dtstamp}\r\nDTSTART:${year}${month}${day}T${hours}${minutes}00\r\nDURATION:PT${
		event.duration.hours
	}H${event.duration.minutes}M\r\nSUMMARY:${event.title}\r\nLOCATION:${
		event.location || ""
	}\r\nURL:${event.url}\r\nEND:VEVENT\r\nEND:VCALENDAR\r\n`;

	const blob = new Blob([icsData], { type: "text/calendar" });
	const url = URL.createObjectURL(blob);

	const a = document.createElement("a");
	a.href = url;
	a.download = "evento.ics";
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	URL.revokeObjectURL(url);
};

export { downloadICS };
