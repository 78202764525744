export enum SITES {
	CUNEF = "cunef_universidad",
	CES = "ces",
}

export enum TEMPLATES_TYPES {
	BASIC_TEMPLATE = "BasicTemplate",
	ERROR_404 = "Error404",
	LANDING_PROGRAM = "LandingProgram",
	LEGAL_TEMPLATE = "LegalTemplate",
	NEWS_DETAIL = "NewsDetail",
	NEWS_LIST = "NewsList",
	PROFESSOR_DETAIL = "ProfessorDetail",
	PROFESSOR_LIST = "ProfessorList",
	PROGRAM_DETAIL = "ProgramDetail",
	PROGRAM_LIST = "ProgramList",
}

export enum PAGES_TYPES {
	ABOUT_US = "about us",
	CONTENT = "content",
	ERROR_404 = "error_404",
	EXPERIENCE = "experience",
	FACULTY_AND_RESEARCH = "faculty and research",
	HOME = "home",
	LANDING = "landing",
	LEGAL = "legal",
	NEWS = "news",
	NEWS_LIST = "news list",
	STAFF = "staff",
	STAFF_LIST = "staff list",
	STUDY = "study",
	STUDY_LIST = "study list",
}

export enum SECTION_TYPE {
	// TEMPLATES
	HOME = "home",
	STUDIES = "studies",
	NEWS = "news",
	LANDING = "landing",

	// TODO: FALTA POR IDENTIFICAR
	ABOUT_US = "about us",
	FACULTY_AND_RESEARCH = "faculty and research",
	INTERNATIONAL = "international",
	EXPERIENCE = "experience",
	VIRTUAL_OFFICE = "virtual office",
	SCHOLARSHIPS = "scholarships",
	ALUMNI = "alumni",
}

export enum PREFIX_PAGE_NAME {
	NEWS = "news",
	STAFF = "research",
	STUDY = "study",
}

export enum PAGE_LANGUAGE {
	"es-ES" = "spanish",
	"en-GB" = "english",
}

export enum STUDY_TYPE {
	UNDERGRADUATE = "undergraduate",
	GRADUATE = "graduate",
	PHD = "phd",
	EXECUTIVE = "executive",
	MINOR = "minor",
}

export enum PRODUCT_AREA {
	BUSINESS_AREA = "business_area",
	LAW_AREA = "law_area",
	ENGINEERING_AREA = "engineering_area",
	AI_AREA = "ai_area",
}

export enum FORM_ID {
	BROCHURE_FORM = "brochure_form",
	LANDING_FORM = "landing_form",
	INFO_FORM = "info_form",
	INTRO_FORM = "intro_form",
	EVENT_FORM = "event_form",
}

export enum NEWS_CATEGORY {
	EVENT = "event",
	NEWS = "news",
	NEWSLETTER = "newsletter",
}

export enum ITEM_LIST_NAME {
	STUDY_LIST = "study_list",
	RELATED_STUDIES = "program_distributor",
}

// NOTE: Available types of custom events
// TODO: Revisar que son necesarios todos esto y que no falta ninguno
export enum DATALAYER_CUSTOM_EVENTS {
	PAGE_VIEW = "page_view",
	SELECT_ITEM = "select_item",
	VIEW_ITEM = "view_item",
	VIEW_ITEM_LIST = "view_item_list",
	ADD_TO_CART = "add_to_cart",
	BEGIN_CHECKOUT = "begin_checkout",
	PURCHASE = "purchase",
	GENERATE_LEAD = "generate_lead",
	FILE_DOWNLOAD = "file_download",
	OPEN_FORM = "open_form",
}

type DataLayerBase = {
	event: "custom_event";
	event_name: DATALAYER_CUSTOM_EVENTS;
	site_name: SITES;
	page_type?: PAGES_TYPES;
};

export type EcommerceProps = {
	item_name?: string;
	item_id?: string;
	item_category?: string;
	item_category_2?: string;
	item_list_name?: ITEM_LIST_NAME;
};

// NOTE: Type for the basic datalayer
export type DatalayerCustomEvent = DataLayerBase & {
	title?: string;
	section_type?: SECTION_TYPE;
	page_language: PAGE_LANGUAGE;
	page_name?: string;
	// NOTE: Fields available for studies
	study_type?: STUDY_TYPE;
	product_id?: string;
	product_name?: string;
	product_area?: PRODUCT_AREA | string;
	// NOTE: Fields available for staff
	staff_department?: string;
	// NOTE: Fields available for news
	news_category?: NEWS_CATEGORY;
	degree_type?: "OFFICIAL" | "CUSTOM";
};

export type DataLayerCustomEventsExtraParams = DataLayerBase & {
	product_area?: PRODUCT_AREA | string;
	product_id?: string;
	product_name?: string;

	item_category?: PRODUCT_AREA | string;
	item_category_2?: STUDY_TYPE;
	item_id?: string;
	item_name?: string;

	currency?: "€";
	item_list_name?: ITEM_LIST_NAME;
	price?: number;
	quantity?: number;

	ecommerce?: {
		items: Array<EcommerceProps>;
	};
	form_id?: FORM_ID;
	lead_id_crm?: string;
	transaction_id?: string;
	programType?: string;
	studyType?: STUDY_TYPE;

	file_name?: string;
	file_extension?: string;
};

export type DataLayerDownloadFileExtraParams = DataLayerBase & {
	file_name?: string;
	file_extension?: string;
};
