import { DownloadCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	heading,
	themeSelector,
	verticalSpacing,
	richtext,
} from "@schemas/presets";
import { button } from "@schemas/presets/button";
const schema: Schema.Module<DownloadCollectionProps> = {
	schemaType: "module",
	component: "DownloadCollection",
	category: "collection",
	displayName: "Download Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false, isMockup: true },
				{
					...richtext,
					title: "Subtitle",
					key: "subtitle",
				},
				{
					title: "Links",
					type: "ComponentArray",
					key: "links",
					mandatory: true,
					whiteList: ["DownloadCard"],
					contentType: "components",
					maxItems: 8,
				},
				{ ...button },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{ ...themeSelector },
				{ ...verticalSpacing },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/DownloadCollection/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/DownloadCollection/Layouts/layout2.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "DownloadCollection",
		links: [],
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		link: { component: "Button" },
		subtitle: "Lorem ipsum",
		layout: "layout1",
		subtheme: "default",
		verticalSpacing: "medium",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/DownloadCollection/thumbnail@1x.png",
	},
};

export default schema;
