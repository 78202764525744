import { DownloadCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<DownloadCardProps> = {
	schemaType: "component",
	component: "DownloadCard",
	displayName: "DownloadCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					title: "File Name",
					key: "fileName",
					hideable: true,
					mandatory: true,
				},
				{
					type: "ConditionalField",
					mandatory: true,
					title: "Content",
					key: "content",
					options: [
						{ value: "urlField", title: "External File", name: "urlField" },
						{ value: "pdfField", title: "Document (PDF)", name: "pdfField" },
					],
					fields: [
						{
							title: "URL Link",
							key: "urlField",
							type: "UrlField",
							condition: "urlField",
						},
						{
							title: "PDF Field",
							key: "pdfField",
							type: "FileField",
							condition: "pdfField",
						},
					],
				},
			],
		},
	],

	default: {
		component: "DownloadCard",
		fileName: "Lorem ipsum",
		content: "pdfField",
	},

	thumbnails: {
		"1x": "/thumbnails/components/OdsCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/OdsCard/thumbnail@2x.png",
	},
};

export default schema;
