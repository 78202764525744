import { QuoteTestimonialProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	image,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";

const schema: Schema.Component<QuoteTestimonialProps> = {
	schemaType: "component",
	component: "QuoteTestimonial",
	category: "content",
	displayName: "QuoteTestimonial",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...image },
				{
					...richtext,
					title: "Content",
					key: "content",
					hideable: false,
					mandatory: true,
				},
				{
					type: "TextField",
					title: "Name",
					key: "name",
					mandatory: true,
					isMockup: true,
				},
				{
					type: "TextField",
					title: "Role",
					key: "role",
					mandatory: true,
					isMockup: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchorID },

				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/QuoteTestimonial/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/QuoteTestimonial/Layouts/layout2.png",
						},
					],
				},
				{ ...themeSelector },
				{ ...verticalSpacing },
			],
		},
	],

	default: {
		component: "QuoteTestimonial",
		content: "Lorem ipsum paragraph",
		name: "Lorem ipsum",
		role: "Lorem ipsum",
		anchorID: null,
		subtheme: "default",
		layout: "layout1",
		image: {
			component: "Image",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/QuoteTestimonial/thumbnail@1x.png",
		"2x": "/thumbnails/modules/QuoteTestimonial/thumbnail@2x.png",
	},
};

export default schema;
