import { DEPARTMENTS } from "./DEPARTMENTS";
import { EVENTS_CATEGORY } from "./EVENTS_CATEGORY";
import { NEWS_CATEGORY } from "./NEWS_CATEGORY";
import { PROFESSOR_TYPE } from "./PROFESSOR_TYPE";

export default {
	DEPARTMENTS,
	EVENTS_CATEGORY,
	NEWS_CATEGORY,
	PROFESSOR_TYPE,
};
