import { Schema } from "@griddo/core";
import { image } from "@schemas/presets";
import { richtext } from "@schemas/presets/richtext";
import { NewQuoteTestimonialProps } from "src/ui/components/NewQuoteTestimonial";

const schema: Schema.Component<NewQuoteTestimonialProps> = {
	schemaType: "component",
	component: "NewQuoteTestimonial",
	category: "content",
	displayName: "NewQuoteTestimonial",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...image },
				{
					...richtext,
					title: "Content",
					key: "content",
					hideable: false,
					mandatory: true,
				},
				{
					type: "TextField",
					title: "Name",
					key: "name",
					isMockup: true,
					hideable: true,
				},
				{
					type: "TextField",
					title: "Role",
					key: "role",
					isMockup: true,
					hideable: true,
				},
			],
		},
	],

	default: {
		component: "NewQuoteTestimonial",
		content: "Lorem ipsum paragraph",
		name: "Lorem ipsum",
		role: "Lorem ipsum",
		anchorID: null,
		subtheme: "default",
		layout: "layout001",
		image: {
			component: "Image",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/NewQuoteTestimonial/thumbnail@1x.png",
		"2x": "/thumbnails/modules/NewQuoteTestimonial/thumbnail@2x.png",
	},
};

export default schema;
