import { EventListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import EventDistributor from "@schemas/modules/EventDistributor";
import { detail } from "@schemas/presets";

const schema: Schema.Template<EventListProps> = {
	dataPacks: ["EVENTS_PACK"],
	schemaType: "template",
	displayName: "Event List",
	component: "EventList",
	type: { label: "The items", value: "items", mode: "list" },

	content: [
		{ ...detail },

		{
			type: "ComponentContainer",
			key: "eventDistributor",
			title: "Event Distributor",
			whiteList: ["EventDistributor"],
		},

		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			contentType: "modules",
			whiteList: [
				"AccordionCollection",
				"BasicContent",
				"BasicText",
				"CardCollection",
				"ChartCollection",
				"CypherCollection",
				"EventDistributor",
				"FeaturedAudiovisual",
				"FeaturedBlock",
				"FeaturedContent",
				"Files",
				"FullImageWithText",
				"FullVideo",
				"HighlightQuote",
				"LandingIntro",
				"LocationMap",
				"LogoCollection",
				"ModalLinkCollection",
				"NewsHighlightDistributor",
				"NewsSimpleDistributor",
				"OdsCollection",
				"OrderedCollection",
				"ProgramsDistributor",
				"QuoteTestimonial",
				"QuoteTestimonialCollection",
				"SocialShare",
				"TabsModules",
				"Wysiwyg",
			],
		},
	],

	default: {
		type: "template",
		templateType: "EventList",
		detail: "Lorem ipsum paragraph",
		eventDistributor: {
			...EventDistributor.default,
			distributorOptions: ["search", "filters"],
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/EventList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/EventList/thumbnail@2x.png",
	},
};

export default schema;
