import { QuoteTestimonialCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { detail, heading, verticalSpacing } from "@schemas/presets";

export const schema: Schema.Module<QuoteTestimonialCollectionProps> = {
	schemaType: "module",
	component: "QuoteTestimonialCollection",
	category: "collection",
	displayName: "QuoteTestimonialCollection",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, hideable: false },
				{ ...detail },
				{ ...verticalSpacing },
				{
					type: "ComponentArray",
					title: "QuoteTestimonialCollection",
					key: "quoteTestimonial",
					contentType: "components",
					whiteList: ["NewQuoteTestimonial"],
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout001",
							img: "/thumbnails/modules/CypherCollection/Layouts/layout1.png",
						},
						{
							value: "layout002",
							img: "/thumbnails/modules/CypherCollection/Layouts/layout2.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "QuoteTestimonialCollection",
		detail: "Lorem ipsum",
		verticalSpacing: "medium",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		quoteTestimonial: [],
	},
};

export default schema;
