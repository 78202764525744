import { EVENTS_PACK_CATEGORY } from "./EVENTS_PACK_CATEGORY";
import { NEWS_PACK_CATEGORY } from "./NEWS_PACK_CATEGORY";
import { ODS_PACK_CATEGORY } from "./ODS_PACK_CATEGORY";
import { PROFESSORS_PACK_CATEGORY } from "./PROFESSORS_PACK_CATEGORY";
import { PROGRAMS_PACK_CATEGORY } from "./PROGRAMS_PACK_CATEGORY";

export default {
	NEWS_PACK_CATEGORY,
	ODS_PACK_CATEGORY,
	PROFESSORS_PACK_CATEGORY,
	PROGRAMS_PACK_CATEGORY,
	EVENTS_PACK_CATEGORY,
};
