import { Schema } from "@griddo/core";

const schema: Schema.Languages = {
	en: {
		name: "English",
		label: "EN",
		alias: ["en_GB"],
	},
	es: {
		name: "Spanish",
		label: "ES",
		alias: ["es_ES"],
	},
};

export default schema;
