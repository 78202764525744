import { Schema } from "@griddo/core";
import en_GB from "@schemas/config/translations/en";
import es_ES from "@schemas/config/translations/es";

const schema: Schema.Translations = {
	en: en_GB,
	es: es_ES,
};

export default schema;
